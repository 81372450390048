import React from "react";
import DataGrid, { Column, Paging, Pager } from "devextreme-react/data-grid";

const NewPayEventTable = ({ data }) => {
  const dataSource = data || [];

  return (
    <DataGrid dataSource={dataSource} showBorders={true}>
      <Paging enabled={true} />
      <Pager showPageSizeSelector={true} showInfo={true} />

      <Column dataField="payDate" caption="Pay Date" />
      <Column dataField="employeeCount" caption="Employee Count" />
      <Column dataField="period" caption="Period" />
      <Column
        dataField="totalNet"
        caption="Total Net"
        dataType="number"
        format="currency"
      />
      <Column dataField="createdOn" caption="Created On" />
      <Column dataField="createdBy" caption="Created By" />
      <Column dataField="id" caption="ID" />
    </DataGrid>
  );
};

export default NewPayEventTable;
