import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
} from "@mui/material";
import {
  clearAllCookies,
  getCookie,
  getLoginSecured,
  logoutUser,
} from "../../utils/api";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";
import { useLoginPopup } from "../../context/LoginPopupContext";

const LoginPopup = ({ open, handleClose, popupType }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { dbId } = useParams();
  const { onReAuthSuccess } = useLoginPopup();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [remember, setRemember] = useState(true);
  const [loginEmail, setLoginEmail] = useState("");
  const [smsSent, setSmsSent] = useState(false);

  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const { apiCall } = useApi();
  const ip = useGetIP();

  const handleLogin = async () => {
    if (process.env.NODE_ENV === "development") {
      return;
    }
    await generateNewApiKeyAndValidate2FA();
  };

  const generateNewApiKeyAndValidate2FA = async () => {
    const generateTokenUrl = `https://${envConfig.apiLogin}/api/v1/en-au/system/generate-new-api-token-details?BaseHostURL=${envConfig.loginUrl}`;
    const loginSecured = getLoginSecured(email, password);
    const generateTokenData = {
      LoginSecuredHash: loginSecured,
    };

    await apiCall({
      url: generateTokenUrl,
      method: "POST",
      skipAuth: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "olivs-api-real-ip": "127.0.0.2",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "browser-agent": window.navigator.userAgent,
        "api-key": "OlivsAuth",
      },
      body: generateTokenData,
      onSuccess: async (generateTokenJson) => {
        if (generateTokenJson?.ApiKey) {
          const verificationUrl = `https://${envConfig.apiLogin}/api/v1/en-au/system/validate-2fa-code?BaseHostURL=${envConfig.loginUrl}`;
          const verificationData = {
            UserLogin2FACode: code,
            RememberMeYN: remember ? "Y" : "N",
          };

          await apiCall({
            url: verificationUrl,
            skipAuth: true,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "olivs-api-real-ip": ip,
              "olivs-api-computer-name": "BTM-WS2019",
              "session-id": generateTokenJson.SessionID,
              "browser-agent": window.navigator.userAgent,
              "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
              "api-key": generateTokenJson.ApiKey + "-0",
            },
            body: verificationData,
            onSuccess: async (verificationJson) => {
              if (verificationJson.SuccessYN === "Y") {
                Cookies.set("apiKey", generateTokenJson.ApiKey, {
                  path: "/",
                });
                Cookies.set(
                  "apiKeyExpirationUTCDate",
                  verificationJson.ApiKeyExpiryUTC.slice(0, -1),
                  {
                    path: "/",
                  },
                );
                Cookies.set("sessionId", generateTokenJson.SessionID, {
                  path: "/",
                });
                Cookies.set("firstName", generateTokenJson.firstName, {
                  path: "/",
                });
                Cookies.set("lastName", generateTokenJson.lastName, {
                  path: "/",
                });
                Cookies.set(
                  "login2FaCodeLastUsedUTCDate",
                  verificationJson.Last2FaUTC,
                  {
                    path: "/",
                  },
                );
                Cookies.set(
                  "loginLastPwdUTCDate",
                  verificationJson.LastPwdUTC,
                  {
                    path: "/",
                  },
                );
                setPassword("");
                setCode("");
                await onReAuthSuccess();
                handleClose();
              } else {
                enqueueSnackbar(verificationJson.ErrorMessage, {
                  variant: "error",
                });
              }
            },
            onError: (errorMessage) => {
              enqueueSnackbar(errorMessage, { variant: "error" });
            },
          });
        }
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });
  };

  const sendCodeViaSMS = async () => {
    setSmsSent(true);
    const smsUrl = `https://${envConfig.apiDev1}/api/v3/en-au/user2fa/send-code-as-sms?BaseHostURL=${envConfig.mainServiceUrl}`;

    await apiCall({
      url: smsUrl,
      ip: ip,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "olivs-api-real-ip": ip,
        "olivs-api-computer-name": "BTM-WS2019",
        "session-id": getCookie("sessionId"),
        "browser-agent": window.navigator.userAgent,
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "api-key": getCookie("apiKey") + "-0",
      },
      onSuccess: (smsJson) => {
        enqueueSnackbar("SMS sent successfully", { variant: "success" });
      },
      onError: (errorMessage) => {
        setSmsSent(false);
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });
  };

  useEffect(() => {
    const email = getCookie("loginEmail");
    setEmail(email || "");
    setLoginEmail(email || "");
  }, [open]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "r") {
        setRemember((prevRemember) => !prevRemember);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Determine the title based on popupType
  const getTitle = () => {
    switch (popupType) {
      case "re-auth":
        return "Re-enter Your Credentials";
      case "login":
      default:
        return "Log in Again";
    }
  };

  // Determine if it's re-authentication
  const isReAuth = popupType === "re-auth";

  return (
    <div
      style={{
        zIndex: 9999999999999,
      }}
    >
      <Dialog
        sx={{
          zIndex: "9999999999999 !important",
          ".MuiModal-backdrop": {
            backdropFilter: "blur(5px)",
          },
        }}
        open={open}
        onClose={(e, reason) => {
          // Prevent closing the dialog by clicking outside or pressing Esc
          return;
        }}
      >
        <Box>
          <DialogTitle>{getTitle()}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              {isReAuth
                ? "Please re-enter your credentials to continue."
                : "Please log in to access your account."}
            </Typography>
            <TextField
              autoFocus
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={email}
              disabled={isReAuth && email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                mt: 2,
                maxWidth: "480px",
                display: "block",
                label: { fontSize: 16, marginTop: 0 },
                "p.Mui-error": {
                  position: "absolute",
                },
              }}
              size="small"
            />
            <TextField
              label="Password"
              type="password"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size="small"
              sx={{
                mt: 2,
                maxWidth: "480px",
                display: "block",
                label: { fontSize: 16, marginTop: 0 },
                "p.Mui-error": {
                  position: "absolute",
                },
              }}
            />
            <TextField
              label="2FA Code"
              type="tel"
              sx={{
                width: "100%",
                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "input[type=number]": {
                  MozAppearance: "textfield",
                },
                label: { fontSize: 16, marginTop: 0 },
                mt: 2,
                maxWidth: "480px",
                display: "block",
                "p.Mui-error": {
                  position: "absolute",
                },
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && code?.length === 6 && password) {
                  handleLogin();
                }
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <Link
              className={"link " + (smsSent ? "disabled" : "")}
              onClick={() => {
                if (!smsSent) sendCodeViaSMS();
              }}
              aria-disabled={smsSent}
              style={{
                color: smsSent ? "#888888" : isDarkMode ? "#AAD269" : "#849F23",
                marginTop: 0,
                cursor: smsSent ? "default" : "pointer",
                fontSize: "14px",
                fontWeight: 700,
                letterSpacing: "0.4px",
                textTransform: "capitalize",
                textDecoration: "none",
              }}
            >
              Send code via SMS
            </Link>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={remember}
                  onChange={(e) => setRemember(e.target.checked)}
                />
              }
              label={
                <span>
                  <u>R</u>emember Me
                </span>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={async () => {
                if (isReAuth) {
                  navigate(-1);
                  return;
                }
                await logoutUser(dbId);
                window.location = `https://${envConfig.loginUrl}?redirectBackTo=${encodeURIComponent(
                  window.location.href,
                )}`;
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={code?.length !== 6 || !password}
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: theme.palette.primary.main,
                },
              }}
              variant="contained"
              onClick={handleLogin}
            >
              {isReAuth ? "Authenticate" : "Log In"}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default LoginPopup;
