// src/components/NewPayEventSettings.jsx
import React, { useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
  Typography,
} from "@mui/material";

const NewPayEventSettings = ({
  listOfPayEventGroups,
  listOfPayEventPeriods,
  defaultDates,
  selectedGroup,
  setSelectedGroup,
  selectedPeriod,
  setSelectedPeriod,
  paymentDate,
  setPaymentDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  customDates,
  setCustomDates,
}) => {
  // Helper function to format dates (extract YYYY-MM-DD)
  const formatDate = (isoString) => isoString.split("T")[0];

  const addPeriod = (date, period) => {
    const newDate = new Date(date);
    switch (period) {
      case "W": // Weekly: add 6 days to include the start day
        newDate.setDate(newDate.getDate() + 6);
        break;
      case "F": // Fortnightly: add 13 days to include the start day
        newDate.setDate(newDate.getDate() + 13);
        break;
      case "M": // Monthly: add 1 month and subtract 1 day to get the last day of the current month
        newDate.setMonth(newDate.getMonth() + 1);
        newDate.setDate(newDate.getDate() - 1);
        break;
      case "Q": // Quarterly: add 3 months and subtract 1 day to get the last day of the quarter
        newDate.setMonth(newDate.getMonth() + 3);
        newDate.setDate(newDate.getDate() - 1);
        break;
      case "A": // Annually: add 1 year and add 1 day
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() + 1);
        break;
      default:
        break;
    }
    return formatDate(newDate.toISOString());
  };

  // Removed the subtractPeriod function and the second useEffect to prevent infinite loops

  // Effect to handle automatic end date calculation based on start date and selected period
  useEffect(() => {
    if (!customDates && startDate && selectedPeriod) {
      const calculatedEndDate = addPeriod(startDate, selectedPeriod);
      if (calculatedEndDate !== endDate) {
        setEndDate(calculatedEndDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, selectedPeriod, customDates]);

  return (
    <Box>
      <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        {/* Group Selection */}
        <Box>
          <Typography variant="subtitle1">Group</Typography>
          <RadioGroup
            value={selectedGroup}
            onChange={(e) => setSelectedGroup(e.target.value)}
          >
            {listOfPayEventGroups.map((group) => (
              <FormControlLabel
                key={group.prcID}
                value={group.prcID.toString()}
                control={<Radio />}
                label={group.prcName}
              />
            ))}
          </RadioGroup>
        </Box>

        {/* Period Selection */}
        <Box>
          <Typography variant="subtitle1">Period</Typography>
          <RadioGroup
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e.target.value)}
          >
            {listOfPayEventPeriods.map((period) => (
              <FormControlLabel
                key={period.Value}
                value={period.Value}
                control={<Radio />}
                label={period.Display}
              />
            ))}
          </RadioGroup>
        </Box>

        {/* Date Fields and Custom Dates Checkbox */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "300px",
          }}
        >
          <TextField
            label="Payment Date"
            type="date"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            disabled={!customDates}
            fullWidth
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            disabled={!customDates}
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={customDates}
                onChange={(e) => setCustomDates(e.target.checked)}
                color="primary"
              />
            }
            label="Custom Dates"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewPayEventSettings;
