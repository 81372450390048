import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Autocomplete,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useSnackbar } from "notistack";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useTheme } from "../../context/ThemeContext";
import { commonThemes } from "../../utils/themes/themes";

const UserMainDetails = ({ selectedUser }) => {
  const { isDarkMode } = useTheme();
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [selectionLists, setSelectionLists] = useState({});
  const [formData, setFormData] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(false);

  const handleInputChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/users/save-user-data-async`;
    try {
      await apiCall({
        url: url,
        method: "POST",
        body: formData,
        onSuccess: () => {
          enqueueSnackbar("User data updated successfully", {
            variant: "success",
          });
        },
        onError: (error) => {
          enqueueSnackbar(error || "Failed to update user data", {
            variant: "error",
          });
        },
      });
    } catch (error) {
      enqueueSnackbar("An unexpected error occurred", { variant: "error" });
    }
  };

  useEffect(() => {
    if (!selectedUser) {
      setFormData(null);
      return;
    }

    const fetchUserData = async () => {
      setLoadingUserData(true);
      const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/users/get-user-data-async?localUserId=${selectedUser.usrID}`;
      try {
        await apiCall({
          url,
          method: "GET",
          onSuccess: (data) => {
            const {
              LocalUser,
              UserAddress,
              NextOfKinPerson,
              NextOfKinPersonAddress,
            } = data.UserData;

            const handleDate = (dateString) => {
              if (!dateString) return null;
              const datePart = dateString.split("T")[0];
              if (datePart === "0001-01-01") {
                return null;
              }
              return dateString;
            };

            const updatedLocalUser = {
              ...LocalUser,
              usrDOB: handleDate(LocalUser.usrDOB),
              usrVisaExpDate: handleDate(LocalUser.usrVisaExpDate),
            };

            const updatedNextOfKinPerson = {
              ...NextOfKinPerson,
              pplDOB: handleDate(NextOfKinPerson.pplDOB),
            };

            setFormData({
              LocalUser: updatedLocalUser,
              UserAddress,
              NextOfKinPerson: updatedNextOfKinPerson,
              NextOfKinPersonAddress,
            });
            setSelectionLists(data.UserData.SelectionLists);
          },
          onError: (error) => {
            enqueueSnackbar(error || "Failed to fetch user data", {
              variant: "error",
            });
          },
        });
      } catch (error) {
        enqueueSnackbar(error || "An unexpected error occurred", {
          variant: "error",
        });
      } finally {
        setLoadingUserData(false);
      }
    };

    fetchUserData();
  }, [apiCall, enqueueSnackbar, selectedUser]);

  const footerTextCreated = formData?.LocalUser?.usrCreatedByFullName
    ? `${formData.LocalUser.usrCreatedByFullName}`
    : "";
  const footerTextUpdated = formData?.LocalUser?.usrUpdatedByFullName
    ? `- ${formData?.LocalUser?.usrUpdatedByFullName}.`
    : ".";
  const footerText = footerTextCreated + footerTextUpdated;

  return (
    <Box p={3}>
      {/* Show loading indicator while fetching user data */}
      {loadingUserData && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}

      {/* Display form only if user data is loaded */}
      {formData && !loadingUserData && (
        <Box mt={3}>
          <Grid container spacing={3}>
            {/* Group 1: Personal Details */}
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h6">Personal Details</Typography>
              <TextField
                fullWidth
                label="Prefix"
                value={formData.LocalUser.usrPrefix || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrPrefix", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="First Name"
                value={formData.LocalUser.usrFirstName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrFirstName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Additional given names"
                value={formData.LocalUser.usrOtherNames || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrOtherNames",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Last Name"
                value={formData.LocalUser.usrLastName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrLastName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Nickname"
                value={formData.LocalUser.usrNickName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrNickName", e.target.value)
                }
                margin="normal"
              />
            </Grid>

            {/* Group 2: Personal Details */}
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h6">Personal Details</Typography>
              <Typography sx={{ marginTop: "24px", marginBottom: "16px" }}>
                User ID: {formData.LocalUser.usrID || ""}
              </Typography>
              <TextField
                fullWidth
                label="Status"
                value={formData.LocalUser.usrStatus || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrStatus", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Code"
                value={formData.LocalUser.usrCode || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrCode", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                value={
                  formData.LocalUser.usrDOB
                    ? formData.LocalUser.usrDOB.split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrDOB", e.target.value)
                }
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              {/* Gender Radio Buttons */}
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="usrGender"
                  value={formData.LocalUser.usrGender || ""}
                  onChange={(e) =>
                    handleInputChange("LocalUser", "usrGender", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="O"
                    control={<Radio />}
                    label="Not provided / Unknown"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Group 3: Contact Details */}
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h6">Contact Details</Typography>
              <TextField
                fullWidth
                label="Email"
                value={formData.LocalUser.usrEmail || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrEmail", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Private Email"
                value={formData.LocalUser.usrPrivateEmail || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrivateEmail",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Phone"
                value={formData.LocalUser.usrPhone || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrPhone", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Home Phone"
                value={formData.LocalUser.usrPhHome || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrPhHome", e.target.value)
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={selectionLists?.ListOfSites || []}
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.ListOfSites?.find(
                    (wr) => wr.Value === formData.LocalUser.usrSite_dpsID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrSite_dpsID",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Assigned to site"
                    margin="normal"
                  />
                )}
              />
            </Grid>

            {/* Group 4: Address */}
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h6">Address</Typography>
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfCountries || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfCountries?.find(
                    (country) =>
                      country.Value === formData.UserAddress.adrCountry,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "UserAddress",
                    "adrCountry",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Country"
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Street 1"
                value={formData.UserAddress.adrStreet1 || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrStreet1", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Street 2"
                value={formData.UserAddress.adrStreet2 || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrStreet2", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Suburb / Town"
                value={formData.UserAddress.adrTown || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrTown", e.target.value)
                }
                margin="normal"
              />
              {/* State field: Conditional rendering based on country */}
              {formData.UserAddress.adrCountry === "au" ? (
                <Autocomplete
                  fullWidth
                  options={
                    selectionLists?.DictionaryOfDisplayValueString
                      ?.ListOfAUStates || []
                  }
                  getOptionLabel={(option) => option.Display || ""}
                  value={
                    selectionLists?.DictionaryOfDisplayValueString?.ListOfAUStates?.find(
                      (state) => state.Value === formData.UserAddress.adrRegion,
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleInputChange(
                      "UserAddress",
                      "adrRegion",
                      newValue ? newValue.Value : "",
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        ".MuiInputBase-root": {
                          borderRadius: "8px",
                          paddingTop: "8px !important",
                          paddingBottom: "9px !important",
                        },
                        label: {
                          ...commonThemes.inputLabel,
                          color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                        },
                      }}
                      label="State"
                      margin="normal"
                    />
                  )}
                />
              ) : (
                <TextField
                  fullWidth
                  label="State"
                  value={formData.UserAddress.adrRegion || ""}
                  onChange={(e) =>
                    handleInputChange(
                      "UserAddress",
                      "adrRegion",
                      e.target.value,
                    )
                  }
                  margin="normal"
                />
              )}
              <TextField
                fullWidth
                label="Post code"
                value={formData.UserAddress.adrPostCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "UserAddress",
                    "adrPostCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
            </Grid>

            {/* Group 5: Payments */}
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h6">Payments</Typography>
              <TextField
                fullWidth
                label="PIN"
                value={formData.LocalUser.usrPIN || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrPIN", e.target.value)
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={selectionLists?.ListOfWorkRights || []}
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.ListOfWorkRights?.find(
                    (wr) => wr.Value === formData.LocalUser.usrWorkRights_ewrID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrWorkRights_ewrID",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Work rights status"
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Visa expiry (if applicable)"
                type="date"
                value={
                  formData.LocalUser.usrVisaExpDate
                    ? formData.LocalUser.usrVisaExpDate.split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrVisaExpDate",
                    e.target.value,
                  )
                }
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              {/* Ready to work on Public Holidays: Radio Buttons */}
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">
                  Ready to work on Public Holidays
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="usrAvailOnPubHolYNAsk"
                  name="usrAvailOnPubHolYNAsk"
                  value={formData.LocalUser.usrAvailOnPubHolYNAsk || ""}
                  onChange={(e) =>
                    handleInputChange(
                      "LocalUser",
                      "usrAvailOnPubHolYNAsk",
                      e.target.value,
                    )
                  }
                >
                  <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                  <FormControlLabel value="N" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="A"
                    control={<Radio />}
                    label="Maybe"
                  />
                </RadioGroup>
              </FormControl>
              {/* <TextField
                fullWidth
                label="Timesheets verification level"
                value={formData.LocalUser.usrVerificationLevel || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrVerificationLevel",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Average cost"
                value={formData.LocalUser.usrAvgCost || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrAvgCost", e.target.value)
                }
                margin="normal"
                type="number"
                sx={{
                  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },

                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              /> */}
              <TextField
                fullWidth
                label="Hourly charge-out rate"
                value={formData.LocalUser.usrHireFee || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrHireFee", e.target.value)
                }
                margin="normal"
              />
            </Grid>

            {/* Group 6: Other Details */}
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h6">Other Details</Typography>
              {/* <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfLanguages || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfLanguages?.find(
                    (lang) => lang.Value === formData.LocalUser.usrLang,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrLang",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Primary language"
                    margin="normal"
                  />
                )}
              /> */}
              <TextField
                fullWidth
                label="Previous first name"
                value={formData.LocalUser.usrPrevFirstName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrevFirstName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Previous last name"
                value={formData.LocalUser.usrPrevLastName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrevLastName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Previous other names"
                value={formData.LocalUser.usrPrevOtherNames || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrevOtherNames",
                    e.target.value,
                  )
                }
                margin="normal"
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Update
            </Button>
            <Box
              sx={{
                mt: 2,
                mb: 2,
                width: "100%",
              }}
            >
              <Typography fontSize="12px" sx={{ textAlign: "right" }}>
                {footerText}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserMainDetails;
